export const numberToShortNumber = (num, precision = 2) => {
  const suffixes = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = suffixes.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision);
    const rounded = Number(formatted); // Remove trailing zeroes after the decimal point
    const final = rounded === Math.floor(rounded) ? rounded : formatted;
    return final + found.suffix;
  }

  return Number(num)?.toFixed(precision);
};
